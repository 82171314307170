import {
  SET_LOGOUT
} from '../actions/types';



const initialState = {
  logout: false
};



const logoutReducer = (
  state = initialState, {
    type,
    payload
  }
) => {
  switch(type) {
    case SET_LOGOUT:
      return {
        ...state,
        logout: true
      };
      default:
        return state;
  }
};
export default logoutReducer;