import {
  SET_CREATE_FUNSRAISER,
  SET_FUNDRAISERS,
  SET_FUNDRAISER_USER_DETAILS,
  SET_IS_FEATURED,
  SET_RECENT_DONORS,
  SET_APPROVE_REJECT_FUNDRAISER,
  SET_SEARCH_FUNDRAISER,
  SET_DONATION_TRANSACTIONS,
  SET_ADD_BANKDETAILS,
  SET_ADMIN_USERS_LIST,
  SET_BANK_DETAILS_LIST,
  SET_DELETE_BANK_ACCOUNT,
  SET_ADMIN_USER_DETAILS,
  SET_ADMIN_USER_FUNDRAISERS,
  SET_ADMIN_USER_DONATIONS,
  SET_DASHBOARD_FUNDRAISERS,
  SET_DASHBOARD_DONATIONS,
  SET_DASHBOARD_COUNT,
  SET_SUSPICIOUS_FUNDRAISER,
  SET_DONORS_FOR_SPECIFIC_FUNDRAISERS,
  SET_EMAIL_TEMPLATE,
  SET_SEARCH_DONATION_TRANSACTION,
  SET_SEARCH_DONOR_SPECIFIC_FUNDRAISER,
  SET_SEARCH_IN_USERS_LIST,
  SET_SEARCH_IN_GET_DONATIONS,
  SET_SEARCH_IN_GET_FUNDRAISERS,
  SET_CREATE_TESTOMONIALS,
  SET_ENABLE_TESTIMONIAL,
  SET_PAYMENT_LIST,
  SET_ADMIN_SEARCH_PAYMENT_LIST,
  SET_PAYMENT,
  SET_FUNDRAISER_OWNER_DETAILS,
  SET_ADMIN_MARK_AS_COMPLETED,
  SET_MARK_WITHDRAWAL_AS_COMPLETED,
  SET_CAMPAIGN_COMPLETION_AND_EXPIRY
} from '../actions/types';


const initialState = {
  createFundraiser: false,
  fundraisersList: {
    fundraisersList: [],
    pagination: {}
  },
  fundraiserUserDetails: {},
  isFeatured: true,
  recentDonors: [],
  approveRejectFundraiser: true,
  donationTransactions: {
    donationTransactions: [],
    pagination: {}
  },
  bankAdded: {
    bankAdded: true,
    visible: false
  },
  adminUsersList: {
    adminUsersList: [],
    pagination: {}
  },
  bankDetailsList: [],
  deleteBankAccount: true,
  adminUserDetails: {},
  adminUserFundraisers: {
    adminUserFundraisers: [],
    pagination: {}
  },
  adminUserDonations: {
    adminUserDonations: [],
    pagination: {}
  },
  dashboardDonation: [],
  dashboardFundraisers: [],
  dashboardCount: {},
  suspiciousFundraiser: false,
  donorsForSpecificFundraier: {
    donorsForSpecificFundraier: [],
    pagination: {}
  },
  emailTemplate: false,
  createTestimonials: false,
  enableTestimonial: false,
  paymentList: {
    paymentList: [],
    pagination: {}
  },
  payment: false,
  fundraiserOwnerDetails: {},
  adminMarkedAsCompleted: false,
  markWithdrawalAsCompleted: false,
  campaignNearingExpiryAndCompletion: {}
}


const adminReducer = (
  state = initialState, {
    type,
    payload
  }) => {
  switch (type) {
    case SET_CREATE_FUNSRAISER:
      return {
        ...state,
        createFundraiser: true
      };
    case SET_FUNDRAISERS:
      return {
        ...state,
        fundraisersList: payload
      };
    case SET_FUNDRAISER_USER_DETAILS:
      return {
        ...state,
        fundraiserUserDetails: payload
      };
    case SET_IS_FEATURED:
      return {
        ...state,
        isFeatured: true
      };
    case SET_RECENT_DONORS:
      return {
        ...state,
        recentDonors: payload
      };
    case SET_APPROVE_REJECT_FUNDRAISER:
      return {
        ...state,
        approveRejectFundraiser: true
      };
    case SET_SEARCH_FUNDRAISER:
      return {
        ...state,
        fundraisersList: payload
      };
    case SET_DONATION_TRANSACTIONS:
      return {
        ...state,
        donationTransactions: payload
      };
    case SET_ADD_BANKDETAILS:
      return {
        ...state,
        bankAdded: payload,
      };
    case SET_ADMIN_USERS_LIST:
      return {
        ...state,
        adminUsersList: payload
      };
    case SET_BANK_DETAILS_LIST:
      return {
        ...state,
        bankDetailsList: payload
      };
    case SET_DELETE_BANK_ACCOUNT:
      return {
        ...state,
        deleteBankAccount: true
      };
    case SET_ADMIN_USER_DETAILS:
      return {
        ...state,
        adminUserDetails: payload
      };
    case SET_ADMIN_USER_FUNDRAISERS:
      return {
        ...state,
        adminUserFundraisers: payload
      };
    case SET_ADMIN_USER_DONATIONS:
      return {
        ...state,
        adminUserDonations: payload
      };
    case SET_DASHBOARD_DONATIONS:
      return {
        ...state,
        dashboardDonation: payload
      };
    case SET_DASHBOARD_FUNDRAISERS:
      return {
        ...state,
        dashboardFundraisers: payload
      };
    case SET_DASHBOARD_COUNT:
      return {
        ...state,
        dashboardCount: payload
      };
    case SET_SUSPICIOUS_FUNDRAISER:
      return {
        ...state,
        suspiciousFundraiser: true
      };
    case SET_DONORS_FOR_SPECIFIC_FUNDRAISERS:
      return {
        ...state,
        donorsForSpecificFundraier: payload
      };
    case SET_EMAIL_TEMPLATE:
      return {
        ...state,
        emailTemplate: true
      };
    case SET_SEARCH_DONATION_TRANSACTION:
      return {
        ...state,
        donationTransactions: payload
      };
    case SET_SEARCH_DONOR_SPECIFIC_FUNDRAISER:
      return {
        ...state,
        donorsForSpecificFundraier: payload
      };
    case SET_SEARCH_IN_USERS_LIST:
      return {
        ...state,
        adminUsersList: payload
      };
    case SET_SEARCH_IN_GET_DONATIONS:
      return {
        ...state,
        adminUserDonations: payload
      };
    case SET_SEARCH_IN_GET_FUNDRAISERS:
      return {
        ...state,
        adminUserFundraisers: payload
      };
    case SET_CREATE_TESTOMONIALS:
      return {
        ...state,
        createTestimonials: payload
      };
    case SET_ENABLE_TESTIMONIAL:
      return {
        ...state,
        enableTestimonial: payload
      };
    case SET_PAYMENT_LIST:
      return {
        ...state,
        paymentList: payload
      };
    case SET_ADMIN_SEARCH_PAYMENT_LIST:
      return {
        ...state,
        paymentList: payload
      };
    case SET_PAYMENT:
      return {
        ...state,
        payment: payload
      };
    case SET_FUNDRAISER_OWNER_DETAILS:
      return {
        ...state,
        fundraiserOwnerDetails: payload
      };
    case SET_ADMIN_MARK_AS_COMPLETED:
      return {
        ...state,
        adminMarkedAsCompleted: payload
      };
    case SET_MARK_WITHDRAWAL_AS_COMPLETED:
      return {
        ...state,
        markWithdrawalAsCompleted: payload
      };
    case SET_CAMPAIGN_COMPLETION_AND_EXPIRY:
      return {
        ...state,
        campaignNearingExpiryAndCompletion: payload
      }
    default:
      return state;
  }
};
export default adminReducer;


