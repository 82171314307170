import {
  SET_SIGN_UP,
  GET_FEATURED_FUND,
  SET_SIGN_IN,
  SET_FUNDRAISER_CATEGORIES,
  SET_CATEGORY_FILTER,
  SET_FUNDRAISERS_FILTER,
  SET_ABOUT_FUNDRAISER,
  SET_COMPLETE_FUNDRAISER,
  SET_SEARCH_IN_SEARCH_RESULTS,
  SET_SEARCH_RESULTS,
  SET_GET_TESTIMONIALS,
  SET_EXPIRED_FUNDRAISER,
  SET_MARK_AS_COMPLETED
} from '../actions/types';

const initialState = {
  registration: {},
  isRegFormFilled: false,
  isSignedUp: false,
  fundRaiserDetails: [],
  isSignedIn: false,
  fundraiserCategories: [],
  fundraiserFilter: [],
  searchResults: [],
  getTestimonials: [],
  expiredFundraiser: false,
  markAsCompleted: false
};

const websiteReducer = (
  state = initialState, {
    type,
    payload
  }) => {
  switch (type) {
    case SET_SIGN_UP:
      return {
        ...state,
        isSignedUp: true
      };
    case SET_ABOUT_FUNDRAISER:
      return {
        ...state,
        registration: {
          ...state.registration,
          ...payload
        }
      };
    case SET_COMPLETE_FUNDRAISER:
      return {
        ...state,
        registration: {
          ...state.registration,
          ...payload
        },
        isRegFormFilled: true
      };
    case GET_FEATURED_FUND:
      return {
        ...state,
        fundRaiserDetails: payload
      };
    case SET_SIGN_IN:
      return {
        ...state,
        isSignedIn: true
      };
    case SET_FUNDRAISER_CATEGORIES:
      return {
        ...state,
        fundraiserCategories: payload
      };
    case SET_CATEGORY_FILTER:
      return {
        ...state,
        fundRaiserDetails: payload
      };
    case SET_FUNDRAISERS_FILTER:
      return {
        ...state,
        fundraiserFilter: payload
      };
    case SET_SEARCH_IN_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: payload
      };
    case SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: payload
      };
    case SET_GET_TESTIMONIALS:
      return {
        ...state,
        getTestimonials: payload
      };
    case SET_EXPIRED_FUNDRAISER:
      return {
        ...state,
        expiredFundraiser: payload
      };
      case SET_MARK_AS_COMPLETED: 
      return {
        ...state,
        markAsCompleted: payload
      }
    default:
      return state;
  }
};
export default websiteReducer;