import {
  SET_SIGN_IN
} from '../actions/types';

const initialState = {
  isAuthenticated: false,
  user: {}
};

const authReducer =  (
  state = initialState, {
    type,
    payload
  }) => {
  switch (type) {
    case SET_SIGN_IN:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};
export default authReducer;