// Admin types
export const SET_CREATE_FUNSRAISER = 'SET_CREATE_FUNSRAISER';
export const SET_FUNDRAISERS = 'SET_FUNDRAISERS';
export const SET_USERS_LIST = 'SET_USERS_LIST';
export const SET_FUNDRAISER_USER_DETAILS = 'SET_FUNDRAISER_USER_DETAILS';
export const SET_IS_FEATURED = 'SET_IS_FEATURED';
export const SET_RECENT_DONORS = 'SET_RECENT_DONORS';
export const SET_APPROVE_REJECT_FUNDRAISER = 'SET_APPROVE_REJECT_FUNDRAISER';
export const SET_SEARCH_FUNDRAISER = 'SET_SEARCH_FUNDRAISER';
export const SET_DONATION_TRANSACTIONS = 'SET_DONATION_TRANSACTIONS';
export const SET_ADD_BANKDETAILS = 'SET_ADD_BANKDETAILS';
export const SET_ADMIN_USERS_LIST = 'SET_ADMIN_USERS_LIST';
export const SET_BANK_DETAILS_LIST = 'SET_BANK_DETAILS_LIST';
export const SET_DELETE_BANK_ACCOUNT = 'SET_DELETE_BANK_ACCOUNT';
export const SET_ADMIN_USER_DETAILS = 'SET_ADMIN_USER_DETAILS';
export const SET_ADMIN_USER_FUNDRAISERS = 'SET_ADMIN_USER_FUNDRAISERS';
export const SET_ADMIN_USER_DONATIONS = 'SET_ADMIN_USER_DONATIONS';
export const SET_DASHBOARD_DONATIONS = 'SET_DASHBOARD_DONATIONS';
export const SET_DASHBOARD_FUNDRAISERS = 'SET_DASHBOARD_FUNDRAISERS';
export const SET_DASHBOARD_COUNT = 'SET_DASHBOARD_COUNT';
export const SET_SUSPICIOUS_FUNDRAISER ='SET_SUSPICIOUS_FUNDRAISER';
export const SET_DONORS_FOR_SPECIFIC_FUNDRAISERS = 'SET_DONORS_FOR_SPECIFIC_FUNDRAISERS';
export const SET_EMAIL_TEMPLATE = 'SET_EMAIL_TEMPLATE';
export const SET_SEARCH_DONATION_TRANSACTION = 'SET_SEARCH_DONATION_TRANSACTION';
export const SET_SEARCH_DONOR_SPECIFIC_FUNDRAISER = 'SET_SEARCH_DONOR_SPECIFIC_FUNDRAISER';
export const SET_SEARCH_IN_USERS_LIST = 'SET_SEARCH_IN_USERS_LIST';
export const SET_SEARCH_IN_GET_DONATIONS = 'SET_SEARCH_IN_GET_DONATIONS';
export const SET_SEARCH_IN_GET_FUNDRAISERS = 'SET_SEARCH_IN_GET_FUNDRAISERS';
export const SET_CREATE_TESTOMONIALS = 'SET_CREATE_TESTOMONIALS';
export const SET_ENABLE_TESTIMONIAL = 'SET_ENABLE_TESTIMONIAL';
export const SET_PAYMENT_LIST = 'SET_PAYMENT_LIST';
export const SET_ADMIN_SEARCH_PAYMENT_LIST = 'SET_ADMIN_SEARCH_PAYMENT_LIST';
export const SET_PAYMENT = 'SET_PAYMENT';
export const SET_FUNDRAISER_OWNER_DETAILS = 'SET_FUNDRAISER_OWNER_DETAILS';
export const SET_ADMIN_MARK_AS_COMPLETED = 'SET_ADMIN_MARK_AS_COMPLETED';
export const SET_MARK_WITHDRAWAL_AS_COMPLETED = 'SET_MARK_WITHDRAWAL_AS_COMPLETED';
export const SET_CAMPAIGN_COMPLETION_AND_EXPIRY = 'SET_CAMPAIGN_COMPLETION_AND_EXPIRY';

// User types
export const SET_USER_FUNDRAISERS = 'SET_USER_FUNDRAISERS,';
export const SET_USER_SEARCH_FUNDRAISER = 'SET_USER_SEARCH_FUNDRAISER';
export const SET_USER_DONATION_TRANSACTION = 'SET_USER_DONATION_TRANSACTION';
export const SET_USER_DASHBOARD = 'SET_USER_DASHBOARD';
export const SET_USER_DASHBOARD_RECENT_DONATIONS = 'SET_USER_DASHBOARD_RECENT_DONATIONS';
export const SET_USER_ONGOING_CAMPAIGNS = 'SET_USER_ONGOING_CAMPAIGNS';
export const SET_PROFILE_DETAILS = 'SET_PROFILE_DETAILS'
export const SET_CHANGE_PASSWORD = 'SET_CHANGE_PASSWORD';
export const SET_CHANGE_PROFILE_PICTURE = 'SET_CHANGE_PROFILE_PICTURE';
export const SET_SEARCH_IN_DONATION_TRANSACTIONS = 'SET_SEARCH_IN_DONATION_TRANSACTIONS';
export const SET_PROFILE_PICTURE ='SET_PROFILE_PICTURE';
export const SET_WITHDRAW_FUNDS = 'SET_WITHDRAW_FUNDS';
export const SET_USER_PAYMENT_LIST = 'SET_USER_PAYMENT_LIST';
export const SET_USER_SEARCH_PAYMENT_LIST = 'SET_USER_SEARCH_PAYMENT_LIST';
export const SET_USER_EDIT_FUNDRAISER = 'SET_USER_EDIT_FUNDRAISER';
export const SET_WITHDRAW_AMOUNT = 'SET_WITHDRAW_AMOUNT';

// Website types
export const SET_SIGN_UP = 'SET_SIGN_UP';
export const GET_FEATURED_FUND = 'GET_FEATURED_FUND';
export const SET_SIGN_IN = 'SET_SIGN_IN';
export const SET_FUNDRAISER_CATEGORIES = 'SET_FUNDRAISER_CATEGORIES';
export const SET_CATEGORY_FILTER = 'SET_CATEGORY_FILTER';
export const SET_FUNDRAISERS_FILTER = 'SET_FUNDRAISERS_FILTER';
export const SET_ABOUT_FUNDRAISER = 'SET_ABOUT_FUNDRAISER'; 
export const SET_COMPLETE_FUNDRAISER = 'SET_COMPLETE_FUNDRAISER';
export const SET_SEARCH_IN_SEARCH_RESULTS = 'SET_SEARCH_IN_SEARCH_RESULTS';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS'; 
export const SET_GET_TESTIMONIALS = 'SET_GET_TESTIMONIALS';
export const SET_EXPIRED_FUNDRAISER = 'SET_EXPIRED_FUNDRAISER';
export const SET_MARK_AS_COMPLETED = 'SET_MARK_AS_COMPLETED';

//Error types
export const SET_ERRORS = 'SET_ERRORS';
export const RESET_ERRORS = 'RESET_ERRORS';

//Logout type
export const SET_LOGOUT = 'SET_LOGOUT';