import Routes from './routes';
import './App.css';

import { Provider } from 'react-redux';
import configureStore from './store';

function App() {
  const store = configureStore();
  // store.subscribe(_ => console.log(store.getState()))
  return (
    <div className="App">
      <Provider store={store}>
        <Routes />
      </Provider>
    </div>
  );
}

export default App;
