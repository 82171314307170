import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';

const Homepage = lazy(() => import('./components/website'));
const Admin = lazy(() => import('./components/admin'));
const User = lazy(() => import('./components/user'));


const Signin = lazy(() => import('./components/website/signin'))
const FundRaiserList = lazy(() => import('./components/website/fundRaiser'))
const Signup = lazy(() => import('./components/website/Signup'))
const FundRaiserDetail = lazy(() => import('./components/website/fundRaiserDetail'))
const SpecificCategoryFundraiser = lazy(() => import('./components/website/SpecificCategoryFundraiser'))
const SearchFundraiser = lazy(() => import('./components/website/SearchFundraiser'))
const MakePayment = lazy(() => import('./components/website/MakePayment'))
const Redirect = lazy(() => import('./components/website/Redirect'));
const AgreeTermsAndConditions = lazy(() => import('./components/website/AgreeTermsAndConditions'));
const Help = lazy(() => import('./components/website/Help'));
const AboutUs = lazy(() => import('./components/website/AboutUs'));

const token = localStorage.getItem('registerToken');


const NotFound = _ =>
  <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={<Link to="/"><Button type="primary">Back Home</Button></Link>}
  />;




const Layout = _ => (
  <Suspense fallback={<LoadingOutlined style={{ fontSize: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "20%" }} spin />}>
    <Router basename="">
      <Switch>
        <Route path='/admin' component={Admin} />
        <Route path='/user' component={User} />
        <Route path='/redirect/:id' component={Redirect} />
        <Route exact path='/' component={Homepage} />


        {
          token ?
            null
            :
            <Route exact path="/login" component={Signin} />
        }
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/fund-raisers" component={FundRaiserList} />
        <Route exact path="/fund-raisers-details" component={FundRaiserDetail} />
        <Route exact path="/specific-category-fundraiser" component={SpecificCategoryFundraiser} />
        <Route exact path="/search-fundraiser" component={SearchFundraiser} />
        <Route exact path="/make-payment" component={MakePayment} />
        <Route exact path="/agree-terms" component={AgreeTermsAndConditions} />
        <Route exact path="/help" component={Help} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  </Suspense>
)

export default Layout;