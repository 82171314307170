import {
  SET_USER_FUNDRAISERS,
  SET_USER_SEARCH_FUNDRAISER,
  SET_USER_DONATION_TRANSACTION,
  SET_USER_DASHBOARD,
  SET_USER_DASHBOARD_RECENT_DONATIONS,
  SET_USER_ONGOING_CAMPAIGNS,
  SET_PROFILE_DETAILS,
  SET_CHANGE_PASSWORD,
  SET_CHANGE_PROFILE_PICTURE,
  SET_SEARCH_IN_DONATION_TRANSACTIONS,
  SET_PROFILE_PICTURE,
  SET_WITHDRAW_FUNDS,
  SET_USER_PAYMENT_LIST,
  SET_USER_SEARCH_PAYMENT_LIST,
  SET_USER_EDIT_FUNDRAISER,
  SET_WITHDRAW_AMOUNT
} from '../actions/types';


const initialState = {
  fundraisersList: {
    fundraisersList: [],
    pagination: {}
  },
  userDonationTransactions: {
    userDonationTransactions: [],
    pagination: {}
  },
  userDashboard: {},
  userDashboardRecentDonations: [],
  userOngoingCampaigns: [],
  profileDetails: {},
  changeProfilePicture: false,
  getProfilePicture: {},
  withdrawFunds: false,
  paymentList: {
    paymentList: [],
    pagination: {}
  },
  editFundraiser: false,
  withdrawAmount: {}
}


const userReducer = (
  state = initialState, {
    type,
    payload
  }) => {
  switch (type) {
    case SET_USER_FUNDRAISERS:
      return {
        ...state,
        fundraisersList: payload
      };
    case SET_USER_SEARCH_FUNDRAISER:
      return {
        ...state,
        fundraisersList: payload
      };
    case SET_USER_DONATION_TRANSACTION:
      return {
        ...state,
        userDonationTransactions: payload
      };
    case SET_USER_DASHBOARD:
      return {
        ...state,
        userDashboard: payload
      };
    case SET_USER_DASHBOARD_RECENT_DONATIONS:
      return {
        ...state,
        userDashboardRecentDonations: payload
      };
    case SET_USER_ONGOING_CAMPAIGNS:
      return {
        ...state,
        userOngoingCampaigns: payload
      }
    case SET_PROFILE_DETAILS:
      return {
        ...state,
        profileDetails: payload
      };
    case SET_CHANGE_PASSWORD:
      return {
        ...state,
        changePassStatus: true
      };
    case SET_CHANGE_PROFILE_PICTURE:
      return {
        ...state,
        changeProfilePicture: payload
      };
    case SET_SEARCH_IN_DONATION_TRANSACTIONS:
      return {
        ...state,
        userDonationTransactions: payload
      }
    case SET_PROFILE_PICTURE:
      return {
        ...state,
        getProfilePicture: payload
      };
    case SET_WITHDRAW_FUNDS:
      return {
        ...state,
        withdrawFunds: payload
      };
    case SET_USER_PAYMENT_LIST:
      return {
        ...state,
        paymentList: payload
      }
    case SET_USER_SEARCH_PAYMENT_LIST:
      return {
        ...state,
        paymentList: payload
      }
    case SET_USER_EDIT_FUNDRAISER:
      return {
        ...state,
        editFundraiser: payload
      };
    case SET_WITHDRAW_AMOUNT:
      return {
        ...state,
        withdrawAmount: payload,
      }
    default:
      return state;
  }
};
export default userReducer;


