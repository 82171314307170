import { combineReducers } from 'redux';

import websiteReducer from './websiteReducer';
import adminReducer from './adminReducer';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import logoutReducer from './logoutReducer';
import userReducer from './userReducer'

export default combineReducers({
  website: websiteReducer,
  admin: adminReducer,
  auth: authReducer,
  error: errorReducer,
  logout: logoutReducer,
  user: userReducer,
});